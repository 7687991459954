






import Vue from 'vue'
import { authStore } from './state/AuthStore';
import { formatStore, LayoutFormFactor } from './state/FormatStore';
import DefaultAvatar from './ui/shared/avatars/default_avatar.png';


export default Vue.extend({
  created() {
    this.loadAuthInfo();
    this.initFormat();
  },

  methods: {
    loadAuthInfo() {
      if (window.UserInfo != undefined) {
        const parsed = JSON.parse(window.UserInfo) as SourceUserInfo;
        authStore.setUser({
          id: parsed.userId,
          name: parsed.name,
          picture: parsed.picture || DefaultAvatar,
        });
      }
    },

    initFormat() {
      this.updateFormFactor();

      window.addEventListener('resize', () => {
        this.updateFormFactor();
      });
    },

    updateFormFactor() {
      const layout = getLayoutFormFactor();
      if (layout != formatStore.layout) {
        formatStore.setLayout(layout);
      }
    },
  },
});

function getLayoutFormFactor(): LayoutFormFactor {
  return window.innerWidth >= 768 ? 'desktop' : 'mobile';
}

interface SourceUserInfo {
  name: string;
  picture: string;
  userId: number;
}
